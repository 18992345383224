import { createRouter, createWebHistory } from 'vue-router'

const Listing = () => import(/* webpackChunkName: "Listing" */ '@/views/Listing.vue')
const Landing = () => import(/* webpackChunkName: "landing" */ '@/views/landing/index.vue')
const Destiny = () => import(/* webpackChunkName: "microsite-destiny" */ '@/views/microsites/destiny/Destiny.vue')
const BoseBlitz = () =>
  import(/* webpackChunkName: "microsite-boseeBlitz" */ '@/views/microsites/bossBlitz/BossBlitz.vue')
const Ignition = () => import(/* webpackChunkName: "microsite-ignition" */ '@/views/microsites/ignition/Ignition.vue')
const Savior = () => import(/* webpackChunkName: "microsite-ignition" */ '@/views/microsites/savior/Savior.vue')
const SixthStar = () =>
  import(/* webpackChunkName: "microsite-SixthStar" */ '@/views/microsites/SixthStar/SixthStar.vue')
const Msf = () => import(/* webpackChunkName: "microsite-ignition" */ '@/views/microsites/msf/Msf.vue')
const NewAge = () => /* webpackChunkName: "microsite-NewAge" */ import('@/views/microsites/NewAge/NewAge.vue')
const GoWest = () => /* webpackChunkName: "microsite-GoWest" */ import('@/views/microsites/GoWest/GoWest.vue')
// const KonoSuba = () => /* webpackChunkName: "microsite-KonoSuba" */ import('@/views/microsites/KonoSuba/KonoSuba.vue')

const routes = [
  {
    path: '/',
    component: Listing,
  },
  {
    path: '/landing',
    component: Landing,
  },
  {
    path: '/micro-site/destiny',
    component: Destiny,
  },
  {
    path: '/micro-site/bossblitz',
    component: BoseBlitz,
  },
  {
    path: '/micro-site/ignition',
    component: Ignition,
  },
  {
    path: `/micro-site/savior`,
    component: Savior,
  },
  {
    path: '/micro-site/sixthstar',
    component: SixthStar,
  },
  {
    path: '/maplestoryfest',
    component: Msf,
  },
  {
    path: '/micro-site/newage',
    component: NewAge,
  },
  {
    path: '/micro-site/gowest',
    component: GoWest,
  },
  // {
  //   path: '/micro-site/bugcatcapoo',
  //   component: () => /* webpackChunkName: "microsite-BugCat" */ import('@/views/microsites/BugCat/index.vue'),
  // },
  // {
  //   path: '/micro-site/konosuba',
  //   component: KonoSuba,
  // },
  {
    path: '/micro-site/darkride',
    component: () => /* webpackChunkName: "microsite-darkride" */ import('@/views/microsites/darkride/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory('/maplestory'),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export { router, routes }
